import { FC } from 'react';
import Box from '@mui/material/Box';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import { inIframe } from '../in-iframe';

const MainTemplate: FC = ({ children }) => {
  const theme = useTheme();
  const isInIframe = inIframe();

  return (
    <Box
      sx={{
        width: '100%',
        margin: isInIframe ? '0 auto 20px' : '0 0 20px',
        [theme.breakpoints.up('sm')]: {
          width: isInIframe ? 600 : 490
        }
      }}
    >
      {!isInIframe && (
        <Box
          component="img"
          sx={{
            maxWidth: 300,
            [theme.breakpoints.down('sm')]: {
              fontSize: '40px',
              padding: '60px 0 0 30px'
            }
          }}
          src="/logo.png"
        />
      )}
      <Box
        sx={{
          background: isInIframe ? '#fff' : blueGrey[50],
          padding: '40px 0',
          position: 'relative',
          [theme.breakpoints.up('sm')]: {
            padding: '40px 60px'
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainTemplate;
