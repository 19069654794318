import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';

const ErrorPage = () => {
  const linkParams = new URLSearchParams({
    subject: '[register.mobilefit.sizestream.com] Invalid share request info',
    body: `
===
Link in question:
${location}`
  });
  const link = 'mailto:support@sizestream.com?' + linkParams.toString();

  return (
    <MainTemplate>
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Oops!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: 'center', maxWidth: 250, margin: '0 auto 50px' }}
      >
        The link that brought you here has a problem
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Please check with the organization that shared the link with you.
        Contact <a href={link}>support@sizestream.com</a> if further assistance
        is needed.
      </Typography>
    </MainTemplate>
  );
};

export default ErrorPage;
