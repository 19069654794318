import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';
import Input from '../components/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { registerUser } from '../api';
import { PageProps } from '../App';

const Register: FC<PageProps> = ({ setPage, userData, startExperience }) => {
  const [value, setValue] = useState({
    firstName: '',
    lastName: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);
    let res;
    if (userData.phone) {
      res = await registerUser({ ...value, phone: `+${userData.phone}` });
    } else {
      res = await registerUser({ ...value, email: userData.email });
    }

    if ('status' in res && res.status === 201) {
      const startSessionResponse = await startExperience();
      setIsLoading(false);

      if (!startSessionResponse) return;

      if (
        'status' in startSessionResponse &&
        startSessionResponse.status === 200
      ) {
        setPage('started');
      } else {
        setPage('landing');
        throw 'Could not start mobile experience for newly created user';
      }
    }
  };

  const restartHandler = async () => {
    setPage('landing');
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={restartHandler}
      >
        Start over
      </Button>
      <Box>
        <Typography
          variant="h6"
          sx={{ textAlign: 'center', marginBottom: '30px' }}
        >
          Nice to meet you!
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          Just a little more, and we can continue!
        </Typography>
        <Box
          sx={{
            width: '300px',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            <Input
              value={value.firstName}
              onChange={changeHandler}
              label="First Name"
              name="firstName"
              sx={{ marginBottom: 2 }}
              required
              disabled={isLoading}
            />
            <Input
              value={value.lastName}
              onChange={changeHandler}
              label="Last Name"
              sx={{ marginBottom: 2 }}
              name="lastName"
              required
              disabled={isLoading}
            />
            <FormGroup sx={{ marginBottom: 3 }}>
              <FormControlLabel
                control={<Checkbox required disabled={isLoading} />}
                label={
                  <Box sx={{ fontSize: '14px', textAlign: 'left' }}>
                    I agree to the{' '}
                    <Link
                      target="_blank"
                      href="https://www.sizestream.com/mobile-app-terms-and-conditions"
                    >
                      terms and conditions{' '}
                    </Link>
                    and{' '}
                    <Link
                      target="_blank"
                      href="https://www.sizestream.com/privacypolicy"
                    >
                      privacy policy
                    </Link>
                  </Box>
                }
              />
            </FormGroup>
            <LoadingButton
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
              loading={isLoading}
            >
              Continue
            </LoadingButton>
          </form>
          {userData.phone ? (
            <Typography variant="subtitle2">SMS charges may apply</Typography>
          ) : null}
        </Box>
      </Box>
    </MainTemplate>
  );
};

export default Register;
