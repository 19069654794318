import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Started from './pages/Started';
import ErrorPage from './pages/ErrorPage';
import backgroundImg from './assets/image/background.png';
import { useTheme } from '@mui/material';
import {
  startMobileExperience,
  StartMobileExperiencePayload,
  isShareParams
} from './api';
import { AxiosResponse } from 'axios';
import { inIframe } from './in-iframe';

export type PageTypes = 'landing' | 'register' | 'started' | 'error';
export type SetUserDataValue = (prev: UserData) => UserData;

export interface PageProps {
  setPage: (value: PageTypes) => void;
  userData: UserData;
  startExperience: () => Promise<Error | AxiosResponse<null, any> | undefined>;
}

export type UserData = {
  email: string;
  phone: string;
};

const Router = () => {
  const [currentPage, _setPage] = useState<PageTypes>('landing');
  const [userData, setUserData] = useState({ email: '', phone: '' });

  const setPage = (page: PageTypes) => {
    if (page === 'landing') setUserData({ email: '', phone: '' });
    return _setPage(page);
  };

  const startExperience = async () => {
    const payload: StartMobileExperiencePayload = {};

    if (userData.phone) {
      payload.phone = `+${userData.phone}`;
    } else if (userData.email) {
      payload.email = userData.email;
    } else {
      throw new Error("Can't start experience without contact info!");
    }

    const searchParams = Object.fromEntries(
      new URLSearchParams(location.search)
    );

    if (isShareParams(searchParams)) payload.shareParams = searchParams;

    if (
      searchParams.appId &&
      !/[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89ab][a-f\d]{3}-[a-f\d]{12}/.test(
        searchParams.appId
      )
    ) {
      setPage('error');
      return;
    }

    return await startMobileExperience(payload);
  };

  const getCurrentPage = () => {
    switch (currentPage) {
      case 'landing':
        return (
          <Landing
            setPage={setPage}
            userData={userData}
            setUserData={setUserData}
            startExperience={startExperience}
          />
        );
      case 'register':
        return (
          <Register
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
          />
        );
      case 'started':
        return (
          <Started
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
          />
        );
      case 'error':
        return <ErrorPage />;
      default:
        return null;
    }
  };

  return getCurrentPage();
};

const App = () => {
  const theme = useTheme();

  if (inIframe()) return <Router />;

  return (
    <Box>
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Router />
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              img: {
                width: '100%',
                height: '100vh',
                objectFit: 'cover'
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'inline-block',
                width: '100%',
                height: '100%',
                opacity: '0.6',
                background: '#fff'
              }
            }}
          >
            <img src={backgroundImg} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;
